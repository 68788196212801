import { withDesign } from "@minted/fancyclothes";
import Color from "color";
import PropTypes from "prop-types";
import React, { Component } from "react";

class SolidButton extends Component {
  render() {
    const { getFontSizeStyles, getFontStyles, getRule, onClick, text } =
      this.props;
    const { currentScopes } = this.context;

    const cornerStyle = getRule("cornerStyle") || "square";
    let borderRadius = "0";

    if (cornerStyle === "rounded") {
      borderRadius = "5px";
    } else if (cornerStyle === "round") {
      borderRadius = "50px";
    }

    const buttonColor = getRule("buttonColor");

    const bgScope =
      currentScopes.find((scope) =>
        Boolean(getRule("backgroundColor", scope)),
      ) || "DesignProvider";
    const fontColor = getRule("backgroundColor", bgScope);
    const borderColor = "transparent";
    const backgroundColor = buttonColor;

    const activeStyle = {
      backgroundColor: Color(backgroundColor).darken(0.1).rgb().string(),
      color: Color(fontColor).lighten(0.08).rgb().string(),
    };

    const style = {
      ...getFontStyles({
        lineHeight: 1.6,
      }),
      ...getFontSizeStyles(14),
      ":active": activeStyle,
      ":focus": activeStyle,
      ":hover": activeStyle,
      backgroundColor,
      backgroundImage: "none",
      borderColor,
      borderRadius,
      borderStyle: "solid",
      borderWidth: "1px",
      color: fontColor,
      cursor: "pointer",
      display: "inline-block",
      padding: "6px 12px",
      textDecoration: "none",
      touchAction: "manipulation",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
    };

    return (
      <button onClick={onClick} style={style}>
        {text}
      </button>
    );
  }
}

SolidButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default withDesign("Button")(SolidButton);
