import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import {
  ReduxActionEntry,
  ReduxCollection,
  ReduxCollectionParams,
  ReduxCreateEntry,
  ReduxDeleteEntry,
  ReduxResource,
  ReduxUpdateEntry,
} from './reduxTypes';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { Site, TimestampedModel } from './modelTypes';

export const defaultSortKey = 'position';

// Takes key/val record where the val is an object type, makes a list
// of all the vals, and then sorts them by a sort key within the val
export const sortedItems = <T>(items: Record<number, T>, sortKey: string = defaultSortKey): T[] => sortBy(values(items), sortKey);

// This is more of an intermediate type used to translate from redux format to our data format
export type StagedUpdate<T extends TimestampedModel> = {
  data: Partial<T> | undefined,
  patch: boolean | undefined,
  update: ReduxActionEntry | undefined,
}

export const selectStagedUpdate = <T extends TimestampedModel>(item: T, resource: ReduxResource<T>): StagedUpdate<T> => {
  const staged: ReduxUpdateEntry<T> = resource.staged.update[item.id];

  if (staged === undefined) {
    return {
      data: undefined,
      patch: undefined,
      update: undefined,
    };
  }

  const {
    data, patch, ...update
  } = staged;

  return {
    data,
    patch,
    update,
  };
};

export const selectStagedCreation =
  <T extends TimestampedModel>(params: ReduxCollectionParams, resource: ReduxResource<T>): ReduxCreateEntry<T> | undefined => isEmpty(params) ?
    resource.staged.create[0] :
    resource.staged.create.find((item) => isEqual(item.params, params));

// This is more of an intermediate type used to translate from redux format to our data format
export type StagedRemoval = {
  delete: ReduxDeleteEntry
}

const selectStagedRemoval = <T extends TimestampedModel>(item: T, resource: ReduxResource<T>): StagedRemoval | undefined=> {
  const staged: ReduxDeleteEntry | undefined = resource.staged.delete[item.id];

  return {
    delete: staged,
  };
};

export type SelectedRecord<T> = {
  data: T,
  delete?: ReduxDeleteEntry,
  stagedFieldNames?: string[],
  update?: ReduxActionEntry
};

export const selectRecord = <T extends TimestampedModel>(resource: ReduxResource<T>, item: T): SelectedRecord<T>=> {
  // Merge staged data and delete status into item if it's present
  const stagedUpdate: StagedUpdate<T> = selectStagedUpdate(item, resource);
  const stagedDelete: StagedRemoval | undefined = selectStagedRemoval(item, resource);
  let selectedRecord: SelectedRecord<T>;

  if (stagedUpdate.patch) {
    // Override patched fields only
    selectedRecord = {
      data: merge({}, item, stagedUpdate.data),
    };
  } else if (stagedUpdate.data) {
    // Override all fields with staged data
    selectedRecord = {
      data: stagedUpdate.data as T,
    };
  } else {
    // Nothing staged, data is the original item
    selectedRecord = {
      data: item,
    };
  }

  if (stagedDelete?.delete) {
    selectedRecord.delete = stagedDelete.delete;
  }

  if (stagedUpdate.data) {
    selectedRecord.update = stagedUpdate.update;
    if (stagedUpdate.patch) {
      selectedRecord.stagedFieldNames = Object.keys(stagedUpdate.data);
    }
  }

  return selectedRecord;
};

export type ResourceItem<T> = {
  data: T,
}

export type ResourceCollectionOptions = {
    includeStaged?: boolean,
    patchFields?: string[],
    sortKey?: string,
}

export type SelectedCollection<T extends TimestampedModel> = {
  fetchTime: number | null,
  hasStagedChanges: boolean,
  isFetching: boolean,
  items: ResourceItem<T>[]
  options?: ResourceCollectionOptions,
  params: ReduxCollectionParams,
  resource: ReduxResource<T>,
  shouldFetch: boolean,
}

// We should re-create a version of this based on what the app currently needs,
// I feel like this can be greatly simplified
export const selectCollection = <T extends TimestampedModel>(resource: ReduxResource<T>, params: ReduxCollectionParams = {}, opts: ResourceCollectionOptions = {}): SelectedCollection<T> => {
  const options: ResourceCollectionOptions = {
    includeStaged: true,
    ...opts,
  };
  // Underscore is because of name overlap, we use "collection" in the code to mean the return value of this
  // method but the redux data has a "collection" of its own that just has IDs but no data
  const _collection: ReduxCollection | undefined = resource.collections.find((collection) => isEqual(collection.params, params));

  if (_collection === undefined) {
    return {
      fetchTime: null,
      hasStagedChanges: false,
      isFetching: false,
      items: [],
      params,
      resource,
      shouldFetch: true,
    };
  }

  // Mapping of resource ID to resource, ex: event.id to event
  const records: Record<number | string, T> = pick(resource.byId, _collection.ids);
  const shouldReverse = options.sortKey ? options.sortKey.indexOf('-') === 0 : false;

  let sorted: T[] = sortedItems(records, options.sortKey);

  sorted = shouldReverse ? sorted.reverse() : sorted;

  let items: SelectedRecord<T>[];
  let hasStagedChanges: boolean;

  // includeStaged is almost always true, so the returned SelectedRecord.items has more than just the data
  if (options.includeStaged) {
    items = sorted.map((item: T) => selectRecord(resource, item)) as SelectedRecord<T>[];
    if (options.patchFields) {
      // Check if there are patchField and stagedFieldNames overlap
      const hasPatchChanges = (item: SelectedRecord<T>) => Boolean(options.patchFields?.find((patchField) => item.stagedFieldNames?.includes(patchField)));
      const hasUpdateErrors = (item: SelectedRecord<T>) => Boolean(item.update?.errors &&
              options.patchFields?.find(
                (patchField) => item.update?.errors[patchField],
              ));
      const hasStagedDelete = (item: SelectedRecord<T>) => Boolean(item.delete);

      hasStagedChanges = items.some((item: SelectedRecord<T>) => hasPatchChanges(item) || hasUpdateErrors(item) || hasStagedDelete(item));
    } else {
      hasStagedChanges = items.some((item: SelectedRecord<T>) => Boolean(item.update || item.delete));
    }
  } else {
    items = sorted.map((item: T) => ({
      data: item,
    }));
    hasStagedChanges = false;
  }

  return {
    fetchTime: _collection.fetchTime,
    hasStagedChanges,
    isFetching: _collection.isFetching,
    items,
    options,
    params,
    resource,
    shouldFetch: _collection.fetchTime === null && !_collection.isFetching,
  };
};

export const selectSite = (sites: ReduxResource<Site>): Site => {
  const collection = selectCollection(sites, {});

  return collection.items[0].data;
};
